<script>
export default {
  mounted() {
    this.$store.dispatch('user/logout');
    window.localStorage.setItem('autologin', false);
    window.localStorage.setItem('session', '');
    document.location = '/';
  }
};
</script>
